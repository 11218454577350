import * as React from "react";
import FadeInAnimation from "./fade_in";
import FadeOutAnimation from "./fade_out";

type FadeState = "FADING_IN" | "FADE_IN_COMPLETE" | "FADING_OUT" | "FADE_OUT_COMPLETE";

type Props = {
  showMilliseconds: number;
  children: React.ReactNode;
};

const TimedFade = (props: Props) => {
  const { showMilliseconds, children } = props;

  const [fadeState, setFadeState] = React.useState<FadeState>("FADING_IN");
  const shouldRender = fadeState !== "FADE_OUT_COMPLETE";

  React.useEffect(() => {
    if (fadeState !== "FADE_IN_COMPLETE") {
      return;
    }
    const timer = setTimeout(() => {
      setFadeState("FADING_OUT");
    }, showMilliseconds);
    return () => clearTimeout(timer);
  }, [fadeState, showMilliseconds]);

  const onAnimationEnd = () => {
    if (fadeState === "FADING_IN") {
      setFadeState("FADE_IN_COMPLETE");
    } else if (fadeState === "FADING_OUT") {
      setFadeState("FADE_OUT_COMPLETE");
    }
  };

  const Animation = fadeState === "FADING_OUT" ? FadeOutAnimation : FadeInAnimation;

  return shouldRender ? <Animation onAnimationEnd={onAnimationEnd}>{children}</Animation> : null;
};

export default TimedFade;
