import * as React from "react";
import styles from "./animations.css";

export const links = () => [{ rel: "stylesheet", href: styles }];

const FadeOutAnimation = (props: {onAnimationEnd: () => void;children: React.ReactNode;}) => {
  React.useEffect(() => {
    setTimeout(() => props.onAnimationEnd(), 900);
  });

  return <div className="fade-out">{props.children}</div>;
};

export default FadeOutAnimation;